$desktop-width: 1024px;
$tablet-width: 768px;
$mobile-width: 768px;

$desktop-header-height: 120px;
$tablet-header-height: 100px;
$mobile-header-height: 75px;
$mobile-header-top-padding: 15px;

$desktop-wrapper-width: 1024px;
$tablet-wrapper-width: 700px;
$mobile-wrapper-width: 600px;

//$baseColor: #EE7942;
//$baseColor: #95846a;

$whiteColor: #f6f6fa;
$baseColor: #2F496E;
$backgroundColor: #F4EADE;
$backgroundColor: #F4EADE;
$emphasizeColor: #2988BC;
$emphasizeColor: #ffa577;
$highLightColor: #ED8C72;
$highLightColor: #d55448;

$baseTexColor: #2f496e;

$highlight-color: #2875C7;

$baseAnimDelay: 1600ms;


/* NEW COLORS */

$primaryColor: #e1b16a;
$primaryColor: #4c3d3b;
$primaryColor2: #7d5558;
$primaryColor3: #896e69;


.spinnerHidden{
  display: none;
}

.minWrapper{
  display:block;
  text-align:center;
  margin: 0 auto;
  max-width: 800px;
}
@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}
@mixin mobile {
  @media (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}

@mixin baseTransitionAnimation {
  transition: transform $baseAnimDelay  ease-out;
  transition: transform $baseAnimDelay  ease-out;
  -webkit-transition: transform $baseAnimDelay ease-out;
  -moz-transition: transform $baseAnimDelay ease-out;
}

.priceTable{
  padding-top:50px;
  @include mobile {
    padding-top:10px;
  }


    width:100%;
  max-width:640px;
  font-family: 'Open Sans', sans-serif;
  font-size:16px;
  text-align: right;
  margin: 0 auto;
  color: $primaryColor;

  th{
    &.lastCell{
      width:20%;
    }
  }
  tr:nth-child(3){
    background-color: lighten($emphasizeColor, 15%);
  }
  tr:nth-child(5){
    background-color: lighten($emphasizeColor, 15%);
  }
  tr:nth-child(8){
    background-color: lighten($emphasizeColor, 15%);
  }
  tr:nth-child(10){
    background-color: lighten($emphasizeColor, 15%);
  }
  td{
    padding:5px;
    &.wholeRow{
      background-color: $primaryColor2;
      text-align:left;
      color: $whiteColor;
    }
    &.firstCell{
      text-align:left;
    }
    &.white{
      background-color: $whiteColor;
    }
  }

  @include mobile {
    width:100%;
    max-width:320px;
    font-size:12px;
    td{
      padding:2px;
      &.wholeRow{
      }
      &.firstCell{
        text-align:left;
        width:15%;
      }
    }
  }

}
.btn{
  &.btn-default {
    box-shadow: 8px 8px 8px -8px rgba(0,0,0,0.49);
    @include mobile {
      box-shadow: none;
    }
    margin-right: 12px;
    margin-top: 4px;
    float: right;
    border-radius: 20%;
    background-color: #896e69;
    color: #f0f0f0;
    border: 0px solid;
    width: 36px;
    height: 36px;
    line-height: 36px;
    padding-top: 3px;
    padding-left: 2px;
    font-weight: bold;
    font-family: Arial;
    cursor: pointer;
    text-align: center;

  }
}


.page-transition-enter {
  transition: opacity .9s;
  opacity: 0;
  position: absolute;
}
.page-transition-enter.page-transition-enter-active {
  opacity: 1;
}
.page-transition-exit {
  transition: opacity .9s;
  opacity: 1;
  position: absolute;
}
.page-transition-exit.page-transition-exit-active {
  opacity: 0;
}



.spinner-enter {
  opacity: 0.1;
}

.spinner-enter.spinner-enter-active {
  transition: opacity $baseAnimDelay;
  opacity: 1;

}

.spinner-exit {
  opacity: 1;
}

.spinner-exit.spinner-exit-active {
  transition: opacity $baseAnimDelay;

  opacity: 0.1;

}

.spinner-appear {
  opacity: 0.1;

}

.spinner-appear.spinner-appear-active {
  transition: opacity $baseAnimDelay;

  opacity: 1;
}




.figureView-enter {
  transition: opacity 2000ms  ease-out;

  //opacity: 0.1;
  //transform: translateY(-100%);
  //transform: scale(1);
  opacity: 0;

}

.figureView-enter.figureView-enter-active {
  transition: opacity 2000ms  ease-out;
  opacity: 1;

  //@include baseTransitionAnimation;
  //opacity: 1;
  //transform: scale(1);


}

.figureView-exit {
  //opacity: 0;
  //transform: scale(0);

}

.figureView-exit.figureView-exit-active {
 // @include baseTransitionAnimation;

  //opacity: 0.9;
  //transform: scale(0);

}

.figureView-appear {
  opacity: 0;
  //transform: scale(1);

}

.figureView-appear.figureView-appear-active {

  //@include baseTransitionAnimation;

  opacity: 1;
  //transform: scale(1);

}







.home-enter {
  transform: translateY(100%);
}

.home-enter.home-enter-active {
  @include baseTransitionAnimation;
  transform: translateY(0%);
}

.home-exit {
  transform: translateY(0%);
}

.home-exit.home-exit-active {
  @include baseTransitionAnimation;
  transform: translateY(100%);
}

.home-appear {
  transform: translateY(100%);
}

.home-appear.home-appear-active {
  @include baseTransitionAnimation;
  transform: translateY(0%);
}


.imager-enter {
  //opacity: 0.1;
  //max-width: 0;
}

.imager-enter.imager-enter-active {
  //transition: opacity 1000ms, max-width 500ms;
  //transition: opacity $baseAnimDelay;
  opacity: 1;
 // max-width: 1200px;

}

.imager-exit {
  //opacity: 1;
}

.imager-exit.imager-exit-active {
  //opacity: 0.1;

}

.imager-appear {
  //opacity: 0.1;
 // max-width: 0;
}

.imager-appear.imager-appear-active {
  //transition: opacity 1000ms , max-width 500ms;
 // transition: opacity $baseAnimDelay;
 // opacity: 1;
  //max-width: 1200px;
}


.title-enter {
  transform: translateY(-100%);
}

.title-enter.title-enter-active {
  @include baseTransitionAnimation;

  transform: translateY(0%);

}

.title-exit {
  transform: translateY(0%);

}

.title-exit.title-exit-active {
  @include baseTransitionAnimation;

  transform: translateY(-100%);

}

.title-appear {
  transform: translateY(-100%);

}

.title-appear.title-appear-active {
  @include baseTransitionAnimation;

  transform: translateY(0%);
}


  body{
    background:$whiteColor;
    margin: 20px;
    @include tablet {
      margin: 0;
    }
    @include mobile {
      margin: 0;
    }

  }

  .haikanLava{
    position: relative;
    margin: 0 auto;
    max-width: $mobile-wrapper-width;
    width:100%;
    height:100vh;
    @include tablet {
      max-width: $tablet-wrapper-width;
    }
    @include desktop {
      max-width: $desktop-wrapper-width;
    }

  }
  /* CHECK CHECK CHECK CHECK CHECK CHECK CHECK CHECK CHECK  */

  .clear{
    clear:both;
  }

  .header {
    background: transparent;
    position: relative;
    z-index:10;
    margin: 0;
    padding: 0;
    padding-top:$mobile-header-top-padding;
    width:100%;
    text-align:center;
    height:$mobile-header-height;
    @include tablet {
      height: $tablet-header-height;
      padding-top:20px;

    }
    @include desktop {
      height: $desktop-header-height;
      padding-top:20px;

    }
    .headerBanner{
      color: $highLightColor;
      bottom:0;
      left:0;
      right:0;
      position: absolute;
      text-align:center;
      padding: 3px;
      padding-top: 5px;
      //font-family: 'Roboto Mono', monospace;
      font-family: Verdana, sans-serif;
      font-weight: 500;

      clear:both;
      text-transform: uppercase;
      font-size:11px;
      letter-spacing: 0.09em;

      @include tablet {
        font-size:18px;
      }
      @include desktop {
        font-size:20px;
      }

      @include mobile {
        color: $whiteColor;
        background-color: $highLightColor;
      }


    }

    .logo{
      margin: 0 auto;
      display: inline-block;
      .headerImg {
        float: left;
        width:auto;
        margin-bottom:6px;
        height:38px;

        @include desktop {
          height:64px;
        }
        @include tablet {
          height: 56px;
        }

      }
      .headerText{
        text-align: left;
        color: $primaryColor3;
        // color: #95846a;
        font-weight: bold;
        font-style: italic;

        font-size:36px;
        font-family: 'Open Sans', sans-serif;
        line-height: 32px;
        //padding-top: 7px;
        padding-top: 4px;
        margin-left: -6px;
        width:auto;
        float: left;

        span{
          display: block;
        }
        @include tablet {
          font-size:55px;
          line-height: 42px;
          padding-top: 9px;
          margin-left: -8px;
          width: auto;
        }
        @include desktop {
          font-size:62px;
          line-height: 58px;
          padding-top: 4px;
          margin-left: -9px;
        }
      }
    }

    .headerInfoText{
      float: right;
      text-align: right;
      color: #95846a;
      font-weight: bold;
      font-style: italic;
      font-size:18px;
      line-height: 10px;
      width:auto;
      padding-right:0px;
      p {
        // font-family: 'Roboto Mono', monospace;
        font-family: Verdana, sans-serif;

      }
      @include mobile {
        width: 100%;
        display: none;
      }
    }
  }
  .mainStyle {

    padding-bottom:30px;
    position: absolute;
    top: $mobile-header-height + $mobile-header-top-padding;
    bottom: 0;
    left:0;
    right:0;

    @include tablet {
      max-width: $tablet-wrapper-width;
      top: $tablet-header-height;
    }
    @include desktop {
      max-width: $desktop-wrapper-width;
      top: $desktop-header-height;
    }



    .polaroids {
      position: relative;
      padding: 0;
      padding-top: 40px;
      padding-bottom: 60px;
      @include mobile {
        padding-top: 0px;
        padding-bottom: 30px;
      }
      overflow: hidden;
      margin: 0 auto;
      position: relative;
      text-align: center;

      a{
        display:inline-block;

        -webkit-transform:rotate(-1deg);
        -moz-transform: rotate(-1deg);
        -o-transform: rotate(-1deg);
        -ms-transform: rotate(-1deg);
        transform: rotate(-1deg);
        -webkit-backface-visibility:hidden; /*prevent rotated text in the caption being jagged in Chrome and Safari*/

        &:nth-child(even){
          -webkit-transform: rotate(2deg);
          -moz-transform: rotate(2deg);
          -o-transform: rotate(2deg);
          -ms-transform: rotate(2deg);
          transform: rotate(2deg);
          @include mobile {
            -webkit-transform:rotate(0deg);
            -moz-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            -ms-transform: rotate(0deg);
            transform: rotate(0deg);
            clear:both;
            text-align: left;
            width: 95%; /*width of the images*/
            margin-left:2%;
            margin-right:2%;
            margin-bottom:0;
            padding-bottom:0;
            vertical-align:top;

          }

        }
        @include mobile {
          -webkit-transform:rotate(0deg);
          -moz-transform: rotate(0deg);
          -o-transform: rotate(0deg);
          -ms-transform: rotate(0deg);
          transform: rotate(0deg);
          clear:both;
          text-align: left;
          width: 95%; /*width of the images*/
          margin-left:2%;
          margin-right:2%;
          margin-bottom:0;
          padding-bottom:0;
          vertical-align:top;
          vertical-align:top;
          line-height:2px;
        }
      }
    }
  }


  figure, figcaption{ /*add any other html5 elements you use*/
    display:block;
  }

  /*Position the figures containing the images and captions*/
  figure{
    position:relative; /*allows precise positioning of the tape in step 5- see support section below for more info*/
    width: 250px; /*width of the images*/
    display: inline-block;
    text-align: center;
    margin:20px;

    @include tablet {
      width: 290px;
    }
    @include mobile {
      clear:both;
      text-align: left;
      width: 95%; /*width of the images*/
      margin-left:2%;
      margin-right:2%;
    }

    .thumbnail{

      position: relative;
      width: 100%;
      height: 100%;
      overflow: hidden;
      @include mobile {
        width: 100px;
        height: 100px;
        float: left;
      }

      img {
        width: 100%;
        height:100%;
        @include mobile {
          position: absolute;
          left: 50%;
          top: 50%;
          height: 100%;
          width: auto;
          -webkit-transform: translate(-50%,-50%);
          -ms-transform: translate(-50%,-50%);
          transform: translate(-50%,-50%);
        }
      }

    }




  }

  figure{
    padding: 6px 8px 10px 8px; /*size of the frame*/
    @include mobile {
      padding: 0px 0px 0px 0px; /*size of the frame*/

    }
    /*give the frame's background colour a gradient*/
    background: lighten($emphasizeColor, 25%); /*fallback colour for browsers that don't support gradients*/
    background: linear-gradient(top, $whiteColor, lighten($whiteColor, 10%) 20%, lighten($whiteColor, 20%) 60%);
    /*give the Polaroids a small drop shadow*/
    -webkit-box-shadow: 4px 4px 8px -4px rgba(0, 0, 0, .75);
    -moz-box-shadow: 4px 4px 8px -4px rgba(0, 0, 0, .75);
    box-shadow: 4px 4px 8px -4px rgba(0, 0, 0, .75);


    &:nth-child(even) { /*see support section below for more info on nth-child*/
      -webkit-box-shadow: 4px 4px 8px -4px rgba(0, 0, 0, .75);
      -moz-box-shadow: 4px 4px 8px -4px rgba(0, 0, 0, .75);
      box-shadow: -4px 4px 8px -4px rgba(0, 0, 0, .75);
    }

    @include mobile {
      background: $whiteColor;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      padding-top:12px;
      padding-bottom:12px;
      margin-top:0px;
      margin-bottom:0px;
      border-bottom: 1px solid #aaaaaa;
    }

  }

  .figureView {
    position: fixed;
    background-color: $whiteColor;
    z-index: 100;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    overflow: scroll;
    //border-radius:5px;
    //border: 2px solid #cccccc;

    .content {
      //width:%100;
      padding-left: 10px;
      padding-right: 10px;
      margin: 0 auto;
      max-width:800px;
    }
    .title {

      transition: transform 600ms;
      background-color: $whiteColor;
      //background: linear-gradient(darken($whiteColor, 3%), $whiteColor 90%);
      padding-top:3px;
      color: $primaryColor2;
      font-size: 23px;
      font-weight: 500;
      line-height: 52px;
      vertical-align: middle;
      letter-spacing: 0.06em;
      //font-family: 'Roboto Mono', monospace;
      font-family: Verdana, sans-serif;

      text-transform: uppercase;
      text-align: center;
      letter-spacing: 0.1em;

      @include mobile {
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
      }
      &.narrow{
        @include desktop {
          max-width: 800px;
          margin: 0 auto;
        }
      }
    }
    img {
      width: 100%;
      vertical-align: top;
      padding: 0;

      &.right {
        width: 48%;
        float: right;
        //margin: 10px;
        margin-top: 5px;
        //margin-right:10px;
        margin-bottom: 20px;
        margin-left: 0;
        @include mobile {
          width: 100%;
          float:none;

        }

        }
      &.left {
        width: 48%;
        float: left;
        //margin: 10px;
        margin-top: 5px;
        //margin-right:10px;
        margin-bottom: 20px;
        margin-right: 10px;

        clear: left;
        @include mobile {
          width: 100%;
          float:none;

        }
      }
      &.wideImg {
        padding: 0;
        margin-top: 10px;
        margin-bottom: 0;
        width: 100%;
      }
    }
    p {
      text-align: justify;
      margin-left: 0;
      margin-right: 10px;
      color: $primaryColor;
      line-height: 125%;
      letter-spacing: 0.03em;
      font-size: 18px;

      font-family: Georgia, serif;
      //font-family: 'Open Sans', sans-serif;

      a {
        color: $highlight-color;
      }

      @include mobile {
        font-size: 16px;

      }
    }
    a {
      color: $highlight-color;
    }

    .galleryImgs {
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 10px;
      img {
        padding: 0;
        margin: 0;
        width: 100%;

      }
    }
  }

  figcaption{
    font-family: 'Roboto Mono', monospace;
    display:table-cell;
    vertical-align:middle;
    text-align:center;
    width: 250px;
    font-size:1.3em;
    color: $primaryColor;
    letter-spacing: 0.09em;
    line-height: normal;
    @include tablet {
      width: 290px;
    }
    @include mobile {
      font-size: 1.2em;
      text-align:left;
      //float:left;
      width: auto;

      padding-left: 10px;
      height:100px;
      //line-height:100px;
      word-break: normal;
      text-wrap: normal;
    }
  }



  figure:before { /*see the support section below to more info on using the :before psuedo element*/
    content: '';
    display: block;
    position: absolute;
    left:5px; /*postion from the left side of the frame (positive value move the tape right, negative moves it left)*/
    top: -15px; /*position from the top of the frame (positive move it above the frame, negative below)*/
    width: 75px; /*width of the tape*/
    height: 25px; /*height of the tape*/
    background-color: darken(rgba(232,230,210,0.7), 15%); /*198colour of the tape, use rgba to make it slightly transparent*/
    background-color: lighten($emphasizeColor, 10%); /*198colour of the tape, use rgba to make it slightly transparent*/
    z-index:100;
    opacity: 0.7;
    /*rotate the tape 12 degrees anti-clockwise*/
    -webkit-transform: rotate(-12deg);
    -moz-transform: rotate(-12deg);
    -o-transform: rotate(-12deg);
    -ms-transform: rotate(-12deg);
    @include mobile {
      display:none;
    }
  }
  /**The tape for the even numbered images needs to be rotated the opposite way, as the images are, and positioned on the other side of the frame, I've also changed the width slightly**/
  figure:nth-child(even):before {
    left:150px;
    top: -15px;
    width: 55px;
    height: 25px;
    -webkit-transform: rotate(12deg);
    -moz-transform: rotate(12deg);
    -o-transform: rotate(12deg);
    -ms-transform: rotate(12deg);
    @include mobile {
      display:none;
    }
  }


  figure:hover {
    -webkit-box-shadow: 4px 4px 8px -4px rgba(237, 140, 114, .75);
    -moz-box-shadow: 4px 4px 8px -4px rgba(237, 140, 114, .75);
    box-shadow: -8px 8px 16px -8px rgba(237, 140, 114, .75);
    figcaption{
      color: $emphasizeColor;
    }
    @include mobile {
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
    }
  }


  // Calendar component
  .jCalendar{
    .year{
      display: block;
      clear: both;
      text-align: center;
      .yearTitle{
        background-color: $whiteColor;
        color: $highLightColor;
        margin-bottom: 10px;
        font-size: 24px;
        line-height: 24px;
        height:24px;
        font-weight: bold;
      }
      .month{
        background-color: $whiteColor;
        color: $whiteColor;
        //width:100%;
        width:280px;
        text-align:center;
        display: inline-block;
        margin: 10px;
        @include mobile {

        }


        .monthTitle{
          background-color: $primaryColor2;
          color: $whiteColor;
          font-family: 'Open Sans', sans-serif;
          font-size: 20px;
          line-height: 28px;
          height:28px;
          font-weight: normal;
          margin-bottom: 8px;
        }
        .day{
          width:30px;
          height:30px;
          line-height: 30px;
          font-weight: bold;
          font-family: 'Open Sans', sans-serif;
          float:left;
          backgroud-color:$whiteColor;
          color: #666666;
          border: 1px solid #666666;
          margin:4px;
          &.reserved{
            background-color: $highLightColor;
            color: $backgroundColor;

          }
          &.prevMonth{
            background-color: $whiteColor;
            border: 1px solid #aaaaaa;

          }
          &.reservedPartially{
            border: 3px solid $highLightColor;
            color: $backgroundColor;

          }
          &.dayName{
            color:$primaryColor2;
            text-transform: uppercase;
            font-weight: bold;
          }
        }
      }
    }
  }

